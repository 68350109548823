import { normalize } from "normalizr";
import {
  FETCH_LIBRARY_SUCCESS,
  FETCH_PLAYLIST_SUCCESS,
  INIT,
  getLibrary,
  getPlaylist,
  storeLibrary,
  storePlaylist,
} from "../actions";
import * as schema from "./schema";
import { initWSS } from "./api";

export const init =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === INIT) {
      initWSS(dispatch);
      dispatch(getLibrary());
      dispatch(getPlaylist());
    }
  };

export const onLibrarySuccess =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === FETCH_LIBRARY_SUCCESS) {
      const { payload } = action;
      Object.keys(payload).forEach((browseCategory) =>
        dispatch(
          storeLibrary(
            browseCategory,
            normalize(payload[browseCategory], schema.arrayOfFilters)
          )
        )
      );
    }
  };

export const onPlaylistSuccess =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === FETCH_PLAYLIST_SUCCESS) {
      const { payload: playlist } = action;
      const currentSong = playlist.shift();
      dispatch(storePlaylist({ currentSong, playlist }));
    }
  };

const libraryMdl = [init, onLibrarySuccess, onPlaylistSuccess];

export default libraryMdl;
