import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import LightBox from "./LightBox";
import { requestSong, togglePrompt } from "../actions";
import {
  KTV_BACKGROUND_GREY,
  KTV_LINE_GREY,
  KTV_PROMPT_GREY,
} from "../constants";
import { getPick, getIsShowingPrompt } from "../reducers";

const PromptLightBox = styled(LightBox)`
  max-width: 350px;
  max-height: 200px;
`;

const Window = styled.div`
  display: grid;
  width: 100%;
  grid-template-areas:
    "message message"
    "cancel confirm";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 1fr;
  background: ${KTV_PROMPT_GREY};
  height: 100%;
  border-radius: 10px;
  border: 1px solid #b3b3b3;
`;

const Button = styled.button`
  border: none;
  margin: 0;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  background: inherit;
  border: 0;
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  :focus {
    outline: 0;
  }
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  color: ${KTV_BACKGROUND_GREY};
  ${({ area }) => area && `grid-area: ${area};`};
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
  padding: 20px;
  h3 {
    margin: 0;
    margin-bottom: 0.5em;
    text-align: center;
  }
  p {
    margin: 0;
  }
  ${({ area }) => area !== "message" && `color: royalblue;`};
  ${({ area }) =>
    area === "confirm" &&
    `
  font-weight: bold;
  border-left: solid 1px ${KTV_LINE_GREY};
`};
  ${({ area }) =>
    area === "message" && `border-bottom: solid 1px ${KTV_LINE_GREY};`};
`;

const Prompt = ({ isShowing, onClose, pick, request }) => {
  const { id, artist, song } = pick;

  const handleConfirm = (pickId) => {
    request(pickId);
    onClose();
  };

  return !isShowing ? null : (
    <PromptLightBox width="75vw" height="45vw">
      <Window>
        <Cell area="message">
          <h3>{song}</h3>
          <p>{artist}</p>
        </Cell>
        <Cell area="cancel">
          <Button onClick={onClose}>Cancel</Button>
        </Cell>
        <Cell area="confirm">
          <Button onClick={() => handleConfirm(id)}>Sing</Button>
        </Cell>
      </Window>
    </PromptLightBox>
  );
};

export default connect(
  (state) => ({
    pick: getPick(state),
    isShowing: getIsShowingPrompt(state),
  }),
  (dispatch) => ({
    onClose: () => dispatch(togglePrompt()),
    request: (id) => dispatch(requestSong(id)),
  })
)(Prompt);
