import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { createGlobalStyle } from "styled-components";
import Router from "./Router";
import Prompt from "./Prompt";
import { init } from "../actions";
import { KTV_BACKGROUND_GREY, KTV_TEXT_GREY } from "../constants";

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
  }

  body {
    margin: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: ${KTV_TEXT_GREY};
    background: ${KTV_BACKGROUND_GREY};
  }

  #root {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const App = ({ init }) => {
  useEffect(() => {
    init();
  }, []);

  return (
    <Fragment>
      <GlobalStyle />
      <Router />
      <Prompt />
    </Fragment>
  );
};

export default connect(
  null,
  { init }
)(App);
