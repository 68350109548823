import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "../design";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  // align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const H1 = styled.h1`
  padding-left: 20px;
  margin-top: 1em;
  margin-bottom: 0;
`;

const Footer = styled.footer`
  padding: 0 20px;
  text-align: right;
`;

const Welcome = () => (
  <Container>
    <H1>Howlin Karaoke</H1>
    <Center>
      <h3>Sign in as</h3>
      <Link to="/browse/eras">
        <Button type="button">Guest</Button>
      </Link>
      <Link to="/party">
        <Button type="button">DJ</Button>
      </Link>
    </Center>
    <Footer>
      <p>by howlin bash</p>
    </Footer>
  </Container>
);

export default Welcome;
