import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import SlideTransition from "../animations/SlideTransition";
import Footer from "./Footer";
import Page from "./Page";
import VisibleFilterList from "./VisibleFilterList";
import { Button } from "../design";
import { getAppIsFiltered } from "../reducers";
import { clearFilters } from "../actions";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Body = styled.div`
  flex: 1;
`;

const Foot = styled.footer`
  flex: 0;
`;

const H1 = styled.h1`
  padding-left: 20px;
  margin-top: 1em;
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  padding-top: 15px;
  margin-top: 1em;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  h1 {
    padding-left: 20px;
    margin-top: 1em;
    margin-bottom: 0;
    margin: 0;
    padding-left: 20px;
  }
`;

const titleCase = (t) => t.charAt(0).toUpperCase() + t.slice(1);

const BrowsePage = ({ clearFilters, appIsFiltered, match }) => {
  const { browseCategory } = match.params;
  return (
    <Page>
      <Container>
        <Body>
          <SlideTransition
            browseCategory={browseCategory}
            render={(nextBrowseCategory) => (
              <>
                <Title>
                  <H1>{titleCase(nextBrowseCategory)}</H1>
                  {appIsFiltered && (
                    <Button type="button" onClick={clearFilters}>
                      Clear Filters
                    </Button>
                  )}
                </Title>
                <VisibleFilterList browseCategory={nextBrowseCategory} />
              </>
            )}
          />
        </Body>
        <Foot>
          <Footer />
        </Foot>
      </Container>
    </Page>
  );
};

export default connect(
  (state) => ({ appIsFiltered: getAppIsFiltered(state) }),
  { clearFilters }
)(BrowsePage);
