import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  getFirstPickMade,
  getIsLoadingNextSong,
  getIsPlaying,
  getIsSkippingBack,
  getIsSkippingForward,
} from "../reducers";
import {
  ended,
  nextSongLoaded,
  toggleSkipBack,
  toggleSkipForward,
} from "../actions";
import mic from "../assets/mic.jpg";
import { HOST } from "../middleware/api";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const Video = styled.video`
  height: 100vh;
`;

const Player = ({
  ended,
  firstPickMade,
  isLoadingNextSong,
  isPlaying,
  isSkippingBack,
  isSkippingForward,
  nextSongLoaded,
  toggleSkipBack,
  toggleSkipForward,
}) => {
  const [uniq, setUniq] = useState(String(Date.now()));
  const videoElem = useRef();

  const fetchVideo = () => {
    setUniq(String(Date.now()));
    videoElem.current.load();
  };

  useEffect(() => {
    videoElem.current.addEventListener("ended", () => ended());
    return () => videoElem.current.removeEventListener("ended", () => ended());
  }, []);

  // Load first song
  useEffect(() => {
    if (firstPickMade) fetchVideo();
  }, [firstPickMade]);

  // Load next song
  useEffect(() => {
    if (isLoadingNextSong) {
      fetchVideo();
      nextSongLoaded();
    }
  }, [isLoadingNextSong]);

  // Play
  useEffect(() => {
    if (isPlaying) videoElem.current.play();
  }, [isPlaying]);

  // Pause
  useEffect(() => {
    if (!isPlaying) videoElem.current.pause();
  }, [isPlaying]);

  // Skip back
  useEffect(() => {
    if (isSkippingBack) {
      videoElem.current.pause();
      videoElem.current.currentTime = 0;
      if (isPlaying) videoElem.current.play();
      toggleSkipBack();
    }
  }, [isPlaying, isSkippingBack]);

  // Skip Forward
  useEffect(() => {
    if (isSkippingForward) {
      ended();
      toggleSkipForward();
    }
  }, [isSkippingForward]);

  return (
    <Container>
      <Video
        controls
        id="video"
        ref={videoElem}
        poster={!isPlaying && mic}
        autoPlay={isPlaying}
        src={`${HOST}/video?${uniq}`}
        type="video/mp4"
      />
    </Container>
  );
};

export default connect(
  (state) => ({
    firstPickMade: getFirstPickMade(state),
    isLoadingNextSong: getIsLoadingNextSong(state),
    isSkippingBack: getIsSkippingBack(state),
    isSkippingForward: getIsSkippingForward(state),
    isPlaying: getIsPlaying(state),
  }),
  { ended, nextSongLoaded, toggleSkipBack, toggleSkipForward }
)(Player);
