import React from "react";
import { connect } from "react-redux";
import { getErrorMessage, getFilterList, getIsFetching } from "../reducers";
import FilterList from "./FilterList";
import { selectFilter } from "../actions";
import FetchError from "./FetchError";

export const VisibleFilterList = ({
  errorMessage,
  selectFilter,
  filterList,
  isFetching,
  ...rest
}) => {
  if (isFetching && !filterList.length) {
    return <p>Loading...</p>;
  }
  if (errorMessage && !filterList.length) {
    return <FetchError message={errorMessage} />;
  }

  return (
    <FilterList {...rest} filterList={filterList} onFilterClick={selectFilter} />
  );
};

const mapStateToProps = (state, { browseCategory }) => ({
  filterList: getFilterList(state, browseCategory),
  isFetching: getIsFetching(state, browseCategory),
  errorMessage: getErrorMessage(state, browseCategory),
  browseCategory
});

export default connect(
  mapStateToProps,
  { selectFilter }
)(VisibleFilterList);
