import React, { Fragment } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import BrowsePage from "./BrowsePage";
import Settings from "./Settings";
import Party from "./Party";
import Player from "./Player";
import Welcome from "./Welcome";

const Router = () => (
  <BrowserRouter>
    <Fragment>
      <Route exact path="/" component={Welcome} />
      <Route path="/browse/:browseCategory?" component={BrowsePage} />
      <Route path="/party" component={Party} />
      <Route path="/player" component={Player} />
      <Route path="/settings" render={Settings} />
    </Fragment>
  </BrowserRouter>
);

export default Router;
