import { combineReducers } from "redux";
import filterLi from "./filterLi";
import { STORE_LIBRARY, TOGGLE_FILTER } from "../actions";

const byId = (browseCategory) => {
  const filtersById = (state = {}, action) => {
    if (action.browseCategory !== browseCategory) {
      return state;
    }

    switch (action.type) {
      case STORE_LIBRARY:
        return {
          ...state,
          ...action.payload.entities.filters,
        };
      case TOGGLE_FILTER:
        return Object.keys(state)
          .map((key) => state[key])
          .reduce(
            (s, f, i) => ({
              ...s,
              [f.id]: filterLi(f, action),
            }),
            {}
          );
      default:
        return state;
    }
  };

  return filtersById;
};

const createList =
  (browseCategory) =>
  (state = [], action) => {
    if (action.browseCategory !== browseCategory) {
      return state;
    }
    switch (action.type) {
      case STORE_LIBRARY:
        return action.payload.result;
      default:
        return state;
    }
  };

const libraryList = (browseCategory) =>
  combineReducers({
    byId: byId(browseCategory),
    listByFilter: createList(browseCategory),
  });

export default combineReducers({
  eras: libraryList("eras"),
  genres: libraryList("genres"),
  artists: libraryList("artists"),
  songs: libraryList("songs"),
});

export const getFilter = (state, browseCategory, id) =>
  state[browseCategory].byId[id];

export const getIds = (state, browseCategory) =>
  state[browseCategory].listByFilter;

export const getSongBrowseCategory = (state, id, browseCategory) =>
  state.songs.byId[id][browseCategory];

export const getUnFilteredListIds = (state, browseCategory) =>
  state[browseCategory].listByFilter;

export const getPick = (state, id) => {
  if (!id) return { artist: "", song: "" };
  const pick = state.songs.byId[id];
  if (!pick) return { artist: "", song: "" };
  const { artists: artistId, label: song } = pick;
  const artist = state.artists.byId[artistId].label;
  return { id, artist, song };
};

export const getSongs = (state, ids) => ids.map((id) => state.songs.byId[id]);
