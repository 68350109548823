export const INIT           = "[app] init";
export const GET_LIBRARY    = "[app] GET Library";
export const GET_PLAYLIST   = "[app] GET Playlist";
export const STORE_LIBRARY  = "[app] Store Library";
export const STORE_PLAYLIST = "[app] Store Playlist";

export const init = () => ({ type: INIT });
export const getLibrary = () => ({ type: GET_LIBRARY });
export const getPlaylist = () => ({ type: GET_PLAYLIST });

export const storeLibrary = (browseCategory, payload) => ({
  type: STORE_LIBRARY,
  browseCategory,
  payload
});

export const storePlaylist = payload => ({
  type: STORE_PLAYLIST,
  payload
});
