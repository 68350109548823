export const API_REQUEST            = "[api] API Request";
export const FETCH_LIBRARY_FAILURE  = "[api] Fetch Library error";
export const FETCH_LIBRARY_SUCCESS  = "[api] Fetch Library success";
export const FETCH_PLAYLIST_FAILURE = "[api] Fetch Playlist error";
export const FETCH_PLAYLIST_SUCCESS = "[api] Fetch Playlist success";

export const apiRequest = ({ url, onSuccess, onFailure }) => ({
  type: API_REQUEST,
  payload: { url, onSuccess, onFailure }
});
