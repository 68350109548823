import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Icon } from "../design";
import Page from "./Page";
import Buffer from "./Buffer";
import { next, pause, play, previous } from "../actions";
import { getCurrentSong, getIsPlaying, getPlaylist } from "../reducers";
import { KTV_BORDER_GREY, KTV_NAV_GREY, KTV_TEXT_GREY } from "../constants";
import { getIsFetching } from "../reducers/api";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
`;

const Body = styled.div`
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const Title = styled.div`
  padding-top: 15px;
  margin-top: 1em;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  h1 {
    padding-left: 20px;
    margin-top: 1em;
    margin-bottom: 0;
    margin: 0;
    padding-left: 20px;
  }
`;

const Ul = styled.ul`
  padding: 0;
  list-style-type: none;
  margin-bottom: 50px;
`;

const Footer = styled.footer`
  width: 100%;
  display: block;
  position: fixed;
  bottom: 0;
`;

const Controls = styled.div`
  list-style-type: none;
  background: ${KTV_NAV_GREY};
  border-top: 1px solid ${KTV_BORDER_GREY};
  margin: 0;
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
`;

const Song = styled.div`
  padding: 15px 0px 15px 25px;
`;

const Hr = styled.hr`
  background: ${KTV_TEXT_GREY};
  border: none;
  height: 1px;
  margin: 0px 0px 0px 25px;
`;

const Text = styled.div`
  font-size: ${({ size }) => size};
`;

const Cell = styled.div`
  padding-top: 10px;
`;

const H1 = styled.h1`
  display: flex;
  align-items: center;
`;

const Party = ({
  currentSong,
  isFetching,
  isPlaying,
  next,
  pause,
  play,
  playlist,
  previous,
}) =>
  isFetching ? (
    "..."
  ) : (
    <Page>
      <Container>
        <Body>
          <Title>
            <H1>Party</H1>
            <Link to="/player">
              <Button type="button">Launch Player</Button>
            </Link>
          </Title>
          <Ul>
            {playlist.map((song) => (
              <li>
                <Song>
                  <Text size="20px">{song.song}</Text>
                  <Text size="15px">{song.artist}</Text>
                </Song>
                <Hr />
              </li>
            ))}
            <Buffer />
          </Ul>
        </Body>
        <Footer>
          <Controls>
            <Text size="20px">{currentSong.song || "Awaiting"}</Text>
            <Text size="15px">{currentSong.artist || "first pick"}</Text>
            <Cell>
              <Icon type="previous" onClick={() => previous()} />
              {isPlaying ? (
                <Icon type="pause" onClick={() => pause()} />
              ) : (
                <Icon type="play" onClick={() => play()} />
              )}
              <Icon type="next" onClick={() => next()} />
            </Cell>
          </Controls>
        </Footer>
      </Container>
    </Page>
  );

export default connect(
  (state) => ({
    currentSong: getCurrentSong(state),
    isFetching: getIsFetching(state),
    isPlaying: getIsPlaying(state),
    playlist: getPlaylist(state),
  }),
  { next, pause, play, previous }
)(Party);
