import { CLEAR_FILTERS, STORE_FILTER } from "../actions";

const filterStack = (state = [], action) => {
  switch (action.type) {
    case CLEAR_FILTERS:
      return [];
    case STORE_FILTER: {
      const { browseCategory, swap } = action;
      if (swap) return state;
      return state.includes(browseCategory)
        ? state.filter((f) => f !== browseCategory)
        : [...state, action.browseCategory];
    }
    default:
      return state;
  }
};

export default filterStack;

export const getAppIsFiltered = (state) => !!state.length;

export const getNextFilters = (state, browseCategory) =>
  !state.length ? state : state.slice(state.indexOf(browseCategory) + 1);
