import { combineReducers } from "redux";
import filterLists, * as fromFilterLists from "./filterLists";
import filters, * as fromFilters from "./filters";
import filterStack, * as fromFilterStack from "./filterStack";
import api, * as fromApi from "./api";
import library, * as fromLibrary from "./library";
import filteredLibrary, * as fromFilteredLibrary from "./filteredLibrary";
import party, * as fromParty from "./party";
import rejected, * as fromRejected from "./rejected";
import ui, * as fromUi from "./ui";

const ktvApp = combineReducers({
  api,
  filters,
  filterStack,
  library,
  filteredLibrary,
  filterLists,
  party,
  rejected,
  ui,
});

export default ktvApp;

export const getAppIsFiltered = (state) =>
  fromFilterStack.getAppIsFiltered(state.filterStack);

const getIds = (state, browseCategory) => {
  const appIsFiltered = getAppIsFiltered(state);

  if (!appIsFiltered) {
    if (browseCategory === "songs") return getSongIds(state);
    return fromLibrary.getUnFilteredListIds(state.library, browseCategory);
  }

  const hasFilterResults = fromFilteredLibrary.hasFilterResults(
    state.filteredLibrary
  );

  if (browseCategory === "songs") {
    const noSongResults = !hasFilterResults && appIsFiltered;
    return noSongResults ? [] : getSongIds(state);
  }

  const visibleList = getOstracisedList(state, browseCategory);
  const noFilterResults =
    !hasFilterResults && !visibleList.length && appIsFiltered;

  if (noFilterResults) return [];

  // This browseCategory has already been filtered
  if (getListFilter(state, browseCategory)) return visibleList;

  // If list has had filter removed but app is still filtered
  if (visibleList.length) return visibleList;

  // The app has been filtered but this browseCategory has not
  return getGeneratedList(state, getSongIds(state), browseCategory);
};

const getOstracisedList = (state, browseCategory) =>
  fromFilterLists.getOstracisedList(state.filterLists, browseCategory);

export const getCurrentSong = (state) => {
  const id = fromParty.getCurrentSong(state.party);
  return fromLibrary.getPick(state.library, id);
};

export const getErrorMessage = (state) => fromApi.getErrorMessage(state.api);

export const getFilterIds = (state) => fromFilters.getFilterIds(state.filters);

export const getFilterList = (state, browseCategory) =>
  getFilters(state, getIds(state, browseCategory), browseCategory);

export const getFilters = (state, ids, browseCategory) =>
  ids.map((id) => fromLibrary.getFilter(state.library, browseCategory, id));

export const getRejectedSongIds = (state, browseCategory) =>
  getFilters(
    state,
    fromRejected.getRejected(state.rejected, browseCategory),
    "songs"
  ).map((song) => song.id);

export const getFirstPickMade = (state) =>
  fromParty.getFirstPickMade(state.party);

export const getGeneratedList = (state, songIds, browseCategory) =>
  [
    ...new Set(
      songIds.map((id) =>
        fromLibrary.getSongBrowseCategory(state.library, id, browseCategory)
      )
    ),
  ].sort((a, b) => a - b);

export const getIsFetching = (state) => fromApi.getIsFetching(state.api);

export const getIsLoadingNextSong = (state) =>
  fromParty.getIsLoadingNextSong(state.party);

export const getIsPlaying = (state) => fromParty.getIsPlaying(state.party);

export const getIsShowingPrompt = (state) =>
  fromUi.getIsShowingPrompt(state.ui);

export const getIsSkippingBack = (state) =>
  fromParty.getIsSkippingBack(state.party);

export const getIsSkippingForward = (state) =>
  fromParty.getIsSkippingForward(state.party);

export const getPick = (state) =>
  fromLibrary.getPick(state.library, fromUi.getPickId(state.ui));

export const getPlaylist = (state) => {
  const ids = fromParty.getPlaylist(state.party);
  return ids.map((id) => fromLibrary.getPick(state.library, id));
};

export const getSongIds = (state) =>
  // filterStack selector + filteredLibLength
  fromFilteredLibrary.hasFilterResults(state.filteredLibrary)
    ? fromFilteredLibrary.getSongIds(state.filteredLibrary)
    : fromLibrary.getUnFilteredListIds(state.library, "songs");

export const getSongs = (state, ids) =>
  fromLibrary.getSongs(state.library, ids);

export const getListFilter = (state, browseCategory) =>
  fromFilters.getListFilter(state.filters, browseCategory);

export const getNextFilters = (state, browseCategory) =>
  fromFilterStack.getNextFilters(state.filterStack, browseCategory);

export const getFilteredLibrary = (state) =>
  fromFilteredLibrary.getSongIds(state.filteredLibrary);
