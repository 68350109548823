import React from "react";
import styled from "styled-components";

const Next = () => (
  <svg width="24" height="24" fillRule="evenodd" clipRule="evenodd">
    <path
      d="M2 24l18-12-18-12v24zm19-24v24h1v-24h-1zm-2.803 12l-15.197 10.132v-20.263l15.197 10.131z"
      fill="white"
    />
  </svg>
);

const Pause = () => (
  <svg width="24" height="24" fillRule="evenodd" clipRule="evenodd">
    <path
      d="M10 24h-6v-24h6v24zm10 0h-6v-24h6v24zm-11-23h-4v22h4v-22zm10 0h-4v22h4v-22z"
      fill="white"
    />
  </svg>
);

const Play = () => (
  <svg width="24" height="24" fillRule="evenodd" clipRule="evenodd">
    <path
      d="M23 12l-22 12v-24l22 12zm-21 10.315l18.912-10.315-18.912-10.315v20.63z"
      fill="white"
    />
  </svg>
);

const Ghost = ({ fill }) => (
  <svg width="24" height="24" fillRule="evenodd" clipRule="evenodd">
    <path
      d="M23 12l-22 12v-24l22 12zm-21 10.315l18.912-10.315-18.912-10.315v20.63z"
      fill={fill}
    />
  </svg>
);

const Previous = () => (
  <svg width="24" height="24" fillRule="evenodd" clipRule="evenodd">
    <path
      d="M22 24l-18-12 18-12v24zm-19-24v24h-1v-24h1zm2.803 12l15.197 10.132v-20.263l-15.197 10.131z"
      fill="white"
    />
  </svg>
);

const iconSwitch = (type, fill) => {
  switch (type) {
    case "next":
      return <Next />;
    case "pause":
      return <Pause />;
    case "play":
      return <Play />;
    case "previous":
      return <Previous />;
    case "ghost":
      return <Ghost fill={fill} />;
    default:
      return null;
  }
};

const Button = styled.button`
  margin: 10px;
  background-color: inherit;
  color: inherit;
  border: none;
  padding: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

const Icon = ({ onClick, type, fill }) => (
  <Button type="button" onClick={() => onClick()}>
    {iconSwitch(type, fill)}
  </Button>
);

export default Icon;
