import { combineReducers } from "redux";
import {
  CLEAR_FILTERS,
  STORE_FILTERED_LIBRARY,
  UPDATE_FILTER,
} from "../actions";

const rejectedList =
  (browseCategory) =>
  (state = [], action) => {
    if (action.browseCategory !== browseCategory) {
      return state;
    }
    switch (action.type) {
      case CLEAR_FILTERS:
        return [];
      case STORE_FILTERED_LIBRARY:
        return action.library.rejected;
      case UPDATE_FILTER: {
        if (action.swap) return action.rejected[browseCategory];
        const rejected = action.rejected[browseCategory];
        return !rejected ? [] : rejected;
      }
      default:
        return state;
    }
  };

const rejected = combineReducers({
  eras: rejectedList("eras"),
  genres: rejectedList("genres"),
  artists: rejectedList("artists"),
});

export default rejected;

export const getRejected = (state, browseCategory) => state[browseCategory];
