export const ENDED            = "[msg] Ended";
export const ENQUEUE          = "[msg] Enqueue";
export const NEXT_SONG_LOADED = "[msg] Load next song";
export const LOAD_NEXT_SONG   = "[msg] Load next song";
export const LOAD_PLAYLIST    = "[msg] Load playlist";
export const NEXT             = "[msg] Next";
export const PAUSE            = "[msg] Pause";
export const PAUSE_PLAYING    = "[msg] Pause playing";
export const PLAY             = "[msg] Play";
export const PREVIOUS         = "[msg] Previous";
export const REQUEST_SONG     = "[msg] Request song";
export const SKIP_BACK        = "[msg] Skip back";
export const SKIP_FORWARD     = "[msg] Skip forward";
export const START_PLAYING    = "[msg] Start playing";

export const ended = () => ({ type: ENDED });
export const next = () => ({ type: NEXT });
export const nextSongLoaded = () => ({ type: NEXT_SONG_LOADED });
export const pause = () => ({ type: PAUSE });
export const play = () => ({ type: PLAY });
export const previous = () => ({ type: PREVIOUS });
export const toggleSkipBack = () => ({ type: SKIP_BACK });
export const toggleSkipForward = () => ({ type: SKIP_FORWARD });

export const requestSong = id => ({
  type: REQUEST_SONG,
  id
});
