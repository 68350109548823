import {
  CLEAR_FILTERS,
  INIT_FILTER,
  SELECT_FILTER,
  initFilter,
  ostraciseList,
  pick,
  resetFilter,
  storeFilter,
  storeFilteredLibrary,
  toggleFilter,
  togglePrompt,
  updateFilter,
} from "../actions";
import {
  getAppIsFiltered,
  getNextFilters,
  getFilterIds,
  getFilteredLibrary,
  getGeneratedList,
  getListFilter,
  getRejectedSongIds,
  getSongIds,
  getSongs,
} from "../reducers";

const rejectSelect = (state, ids, filterType, filter) => {
  const songs = getSongs(state, ids);
  return songs.reduce(
    (sorted, song) => {
      if (song[filterType] === filter) {
        sorted.selected.push(song.id);
      } else {
        sorted.rejected.push(song.id);
      }
      return sorted;
    },
    { selected: [], rejected: [] }
  );
};

const addFilter = (dispatch, state, browseCategory, filterId) => {
  dispatch(
    storeFilteredLibrary(
      browseCategory,
      rejectSelect(state, getSongIds(state), browseCategory, filterId)
    )
  );

  // Current filter has not yet been stored so ostracised list will be generated from library before filter was applied
  dispatch(
    ostraciseList(
      browseCategory,
      getGeneratedList(state, getSongIds(state), browseCategory),
      getSongIds(state)
    )
  );
};

const cacheAndFilter = (
  state,
  dispatch,
  browseCategory,
  ids,
  filterStack,
  newRejected,
  swap
) => {
  const payload = { browseCategory, swap, rejected: {} };
  payload.rejected[browseCategory] = newRejected;

  let idsToRedistribute = ids;

  filterStack.forEach((filterType) => {
    const filter = getListFilter(state, filterType);
    const { selected, rejected } = rejectSelect(
      state,
      idsToRedistribute,
      filterType,
      filter
    );
    payload.rejected[filterType] = rejected;
    idsToRedistribute = selected;
  });

  // if (!idsToRedistribute.length) {
  //   console.log("caught it");
  //   return;
  // }

  payload.library = idsToRedistribute;

  dispatch(updateFilter(payload));
};

/*
 * Replace filtered library and rebuild rejected caches
 *
 * Sort prev rejected by new filter (selected/rejected)
 * new rejected cache is aboves rejected + filtered library and all remaining
 * filters rejected caches.
 * Then iterate through remaining filters creating new rejected caches from
 * The previous filters selected ids.
 * last filters selected ids becomes new filteredLibrary.
 */
const switchFilter = (dispatch, state, browseCategory, filterId) => {
  const filterStack = getNextFilters(state, browseCategory);
  const prevRejectedIds = getRejectedSongIds(state, browseCategory);

  const { selected, rejected } = rejectSelect(
    state,
    prevRejectedIds,
    browseCategory,
    filterId
  );

  const newRejected = rejected.concat(getFilteredLibrary(state));
  filterStack.forEach((type) => {
    newRejected.concat(getRejectedSongIds(state, type));
  });

  cacheAndFilter(
    state,
    dispatch,
    browseCategory,
    selected,
    filterStack,
    newRejected,
    true
  );
};

/*
 * Redistribute songs previously rejected by removed filter
 *
 * Get next highest filter in the stack and sort prevRejected by it.
 * rejected ids are appended to that filters rejected cache.
 * If that filter is the top of the stack, selected ids are appended to the
 * filteredLibrary.
 * Else selected ids are sorted by next filter in stack as loop repeats.
 */
const removeFilter = (dispatch, state, browseCategory) => {
  // The app's only filter was removed so empty filteredLibrary
  if (!getAppIsFiltered(state)) {
    dispatch(resetFilter());
    return;
  }

  const filterStack = getNextFilters(state, browseCategory);
  const prevRejectedIds = getRejectedSongIds(state, browseCategory);

  cacheAndFilter(
    state,
    dispatch,
    browseCategory,
    prevRejectedIds,
    filterStack,
    null,
    false
  );
};

export const filterLibrary =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === INIT_FILTER) {
      const { browseCategory, filterId } = action;
      const prevFilter = getListFilter(getState(), browseCategory);

      // Add filter
      if (!prevFilter) {
        dispatch(storeFilter(browseCategory, filterId));
        addFilter(dispatch, getState(), browseCategory, filterId);
        return;
      }

      // Remove filter
      if (prevFilter === filterId) {
        dispatch(storeFilter(browseCategory, filterId));
        removeFilter(dispatch, getState(), browseCategory);
        return;
      }

      // Switch filter
      if (prevFilter !== filterId) {
        dispatch(storeFilter(browseCategory, filterId, true));
        switchFilter(dispatch, getState(), browseCategory, filterId);
      }
    }
  };

const receiveFilter =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === SELECT_FILTER) {
      const { browseCategory, filterId } = action;
      if (browseCategory === "songs") {
        dispatch(pick(filterId));
        dispatch(togglePrompt());
        return;
      }
      dispatch(toggleFilter(browseCategory, filterId));
      dispatch(initFilter(browseCategory, filterId));
    }
  };

const clearFilters =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === CLEAR_FILTERS) {
      const filterIds = getFilterIds(getState());
      filterIds.forEach(([browseCategory, filterId]) => {
        dispatch(toggleFilter(browseCategory, filterId));
      });
    }
  };

const filterMdl = [clearFilters, filterLibrary, receiveFilter];

export default filterMdl;
