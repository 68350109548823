import React from "react";
import styled from "styled-components";
import Page from "./Page";

const H1 = styled.h1`
  padding-left: 20px;
  margin-top: 1em;
  margin-bottom: 0;
`;

const Settings = () => (
  <Page>
    <H1>Settings</H1>
  </Page>
);

export default Settings;
