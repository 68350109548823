import { combineReducers } from "redux";
import { CLEAR_FILTERS, STORE_FILTER } from "../actions";

const createFilter = (browseCategory) => {
  const filter = (state = null, action) => {
    if (action.browseCategory !== browseCategory) {
      return state;
    }

    switch (action.type) {
      case CLEAR_FILTERS:
        return null;
      case STORE_FILTER:
        return action.filterId === state ? null : action.filterId;
      default:
        return state;
    }
  };

  return filter;
};

const filters = combineReducers({
  eras: createFilter("eras"),
  genres: createFilter("genres"),
  artists: createFilter("artists"),
});

export default filters;

export const getListFilter = (state, browseCategory) => state[browseCategory];
export const getFilterIds = (state) =>
  Object.entries(state).filter(([k, v]) => v);
