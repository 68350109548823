import { combineReducers } from "redux";
import { FETCH_LIBRARY_FAILURE, GET_LIBRARY, STORE_LIBRARY } from "../actions";

const isFetching = (state = false, action) => {
  switch (action.type) {
    case GET_LIBRARY:
      return true;
    case STORE_LIBRARY:
    case FETCH_LIBRARY_FAILURE:
      return false;
    default:
      return state;
  }
};

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_LIBRARY_FAILURE:
      return action.message;
    case GET_LIBRARY:
    case STORE_LIBRARY:
      return null;
    default:
      return state;
  }
};

const api = combineReducers({
  isFetching,
  errorMessage
});

export default api;

export const getIsFetching = state => state.isFetching;
export const getErrorMessage = state => state.errorMessage;
