import React from "react";
import styled from "styled-components/macro";

const Underlay = styled.div`
  top: 0;
  z-index: 5;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: black;
`;

const Overlay = styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
`;

const ContentFrame = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const LightBox = ({ children, width, height, ...props }) => {
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <Underlay />
      <Overlay>
        <ContentFrame
          width={width}
          height={height}
          onClick={handleClick}
          {...props}
        >
          {children}
        </ContentFrame>
      </Overlay>
    </>
  );
};

export default LightBox;
