import {
  ENQUEUE,
  LOAD_NEXT_SONG,
  LOAD_PLAYLIST,
  NEXT_SONG_LOADED,
  PAUSE_PLAYING,
  SKIP_BACK,
  SKIP_FORWARD,
  START_PLAYING,
  STORE_PLAYLIST
} from "../actions";

const initParty = {
  currentSong: null,
  firstPickMade: false,
  isLoadingNextSong: false,
  isPlaying: false,
  isSkippingBack: false,
  isSkippingForward: false,
  playlist: []
};

const party = (state = initParty, action) => {
  switch (action.type) {
    case ENQUEUE:
      return {
        ...state,
        playlist: [...state.playlist, action.id]
      };
    case STORE_PLAYLIST: {
      const { currentSong, playlist } = action.payload;
      return {
        ...state,
        currentSong,
        playlist
      };
    }
    case LOAD_NEXT_SONG: {
      const nextPlaylist = [...state.playlist];
      const nextSong = nextPlaylist.shift();
      return {
        ...state,
        currentSong: nextSong,
        playlist: nextPlaylist,
        isLoadingNextSong: !state.isLoadingNextSong
      };
    }
    case NEXT_SONG_LOADED:
      return {
        ...state,
        isLoadingNextSong: !state.isLoadingNextSong
      };
    case LOAD_PLAYLIST:
      return {
        ...state,
        currentSong: action.id,
        firstPickMade: true
      };
    case PAUSE_PLAYING:
      return {
        ...state,
        isPlaying: false
      };
    case SKIP_BACK:
      return {
        ...state,
        isSkippingBack: !state.isSkippingBack
      };
    case SKIP_FORWARD:
      return {
        ...state,
        isSkippingForward: !state.isSkippingForward
      };
    case START_PLAYING:
      return {
        ...state,
        isPlaying: true
      };
    default:
      return state;
  }
};

export default party;

export const getCurrentSong = state => state.currentSong;
export const getFirstPickMade = state => state.firstPickMade;
export const getIsLoadingNextSong = state => state.isLoadingNextSong;
export const getIsPlaying = state => state.isPlaying;
export const getIsSkippingBack = state => state.isSkippingBack;
export const getIsSkippingForward = state => state.isSkippingForward;
export const getPlaylist = state => state.playlist;
