import { PICK, TOGGLE_PROMPT } from "../actions";

const initUi = {
  pickId: "",
  prompt: false
};

const ui = (state = initUi, action) => {
  switch (action.type) {
    case PICK:
      return {
        ...state,
        pickId: action.songId
      };
    case TOGGLE_PROMPT:
      return {
        ...state,
        prompt: !state.prompt
      };
    default:
      return state;
  }
};

export default ui;

export const getIsShowingPrompt = state => state.prompt;
export const getPickId = state => state.pickId;
