import { combineReducers } from "redux";
import { CLEAR_FILTERS, OSTRACISE_LIST, RESET_FILTER } from "../actions";

const initFilterList = { list: [], songs: [] };

const filterList =
  (browseCategory) =>
  (state = initFilterList, action) => {
    if (action.browseCategory !== browseCategory) {
      return state;
    }
    switch (action.type) {
      case CLEAR_FILTERS:
      case RESET_FILTER:
        return { list: [], songs: [] };
      case OSTRACISE_LIST:
        return {
          list: action.list,
          songs: action.songs,
        };
      default:
        return state;
    }
  };

const filterLists = combineReducers({
  eras: filterList("eras"),
  genres: filterList("genres"),
  artists: filterList("artists"),
});

export default filterLists;

export const getOstracisedList = (state, browseCategory) =>
  state[browseCategory].list;
