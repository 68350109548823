import io from "socket.io-client";
import {
  API_REQUEST,
  ENDED,
  FETCH_LIBRARY_FAILURE,
  FETCH_LIBRARY_SUCCESS,
  FETCH_PLAYLIST_FAILURE,
  FETCH_PLAYLIST_SUCCESS,
  GET_LIBRARY,
  GET_PLAYLIST,
  NEXT,
  PAUSE,
  PLAY,
  PREVIOUS,
  REQUEST_SONG,
  apiRequest,
} from "../actions";

const { origin } = window.location;

export const HOST =
  process.env.NODE_ENV === "development"
    ? origin.replace(/3000$/, "4000")
    : origin;

const WS_HOST = HOST.replace(/^http/, "ws");

const socket = io(WS_HOST);

export const initWSS = (dispatch) => {
  socket.on("message", (event) => {
    dispatch(event);
  });
};

/* Function which aims to handle receive messages from server */

export const requestSwitch =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case GET_LIBRARY:
        dispatch(
          apiRequest({
            url: "/library",
            onSuccess: FETCH_LIBRARY_SUCCESS,
            onFailure: FETCH_LIBRARY_FAILURE,
          })
        );
        break;
      case GET_PLAYLIST:
        dispatch(
          apiRequest({
            url: "/playlist",
            onSuccess: FETCH_PLAYLIST_SUCCESS,
            onFailure: FETCH_PLAYLIST_FAILURE,
          })
        );
        break;
      default:
        break;
    }
  };

export const get =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === API_REQUEST) {
      const { url, onFailure, onSuccess } = action.payload;

      fetch(HOST + url)
        .then((response) => {
          // Handle bad response
          if (!response.ok) {
            return response.text().then(
              (body) => {
                const error = { status: response.status, body };
                throw error;
              },
              () => {
                const error = { status: response.status };
                throw error;
              }
            );
          }

          return response.json();
        })
        .then(
          (payload) =>
            dispatch({
              type: onSuccess,
              request: action,
              payload,
            }),
          (error) => dispatch({ type: onFailure, error })
        );
    }
  };

export const emit = () => (next) => (action) => {
  next(action);

  const isMessage = () => {
    switch (action.type) {
      case ENDED:
      case NEXT:
      case PAUSE:
      case PLAY:
      case PREVIOUS:
      case REQUEST_SONG:
        return action;
      default:
        return null;
    }
  };
  const message = isMessage();
  if (message) socket.emit("message", message);
};

// const apiMdl = [emit, get, on, requestSwitch];
const apiMdl = [emit, get, requestSwitch];

export default apiMdl;
