import { applyMiddleware, compose, createStore } from "redux";
import appMiddlewares from "./middleware";
import ktvApp from "./reducers";

const configureStore = () => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return createStore(
    ktvApp,
    composeEnhancers(applyMiddleware(...appMiddlewares))
  );
};

export default configureStore;
