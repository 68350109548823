import { TOGGLE_FILTER } from "../actions";

const filterLi = (state, action) => {
  switch (action.type) {
    case TOGGLE_FILTER:
      if (state.active) {
        return {
          ...state,
          active: ""
        };
      }

      if (state.id !== action.filterId) {
        return state;
      }

      return {
        ...state,
        active: "active"
      };
    default:
      return state;
  }
};

export default filterLi;
