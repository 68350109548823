import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  KTV_BACKGROUND_GREY,
  KTV_DARK_BLUE,
  KTV_TEXT_GREY
} from "../constants";

const Ul = styled.ul`
  padding: 0px;
  list-style-type: none;
`;

const Button = styled.button`
  display: inline-block;
  border: none;
  padding: 15px 0px 15px 25px;
  margin: 0;
  background: ${({ active }) => (active ? KTV_DARK_BLUE : KTV_BACKGROUND_GREY)};
  color: ${KTV_TEXT_GREY};
  font-size: 1.3em;
  text-align: left;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  :focus {
    outline: 0;
  }
`;

const Hr = styled.hr`
  background: ${KTV_TEXT_GREY};
  border: none;
  height: 1px;
  margin: 0px 0px 0px 25px;
`;

const Buffer = styled.div`
  min-height: 80px;
`;

const next = {
  eras: "/browse/genres",
  genres: "/browse/artists",
  artists: "/browse/songs",
  songs: "/browse/songs"
};

const FilterList = ({ browseCategory, filterList, onFilterClick }) => {
  const [selected, setSelected] = useState(false);

  return (
    <>
      <Ul>
        {browseCategory !== "songs" && (
          <li key={0}>
            <Link to={next[browseCategory]}>
              <Button
                onClick={() => setSelected(state => !state)}
                active={selected}
                type="button"
              >
                <span>All</span>
              </Button>
            </Link>
            <Hr />
          </li>
        )}
        {filterList.map(({ id, label, active }) => (
          <li key={id}>
            <Link to={!active && next[browseCategory]}>
              <Button
                onClick={() => onFilterClick(browseCategory, id)}
                active={active}
                type="button"
              >
                <span>{label}</span>
              </Button>
            </Link>
            <Hr />
          </li>
        ))}
      </Ul>
      <Buffer />
    </>
  );
};

export default FilterList;
