import React from "react";
import NavBar from "./NavBar";

const links = [
  { label: "eras", route: "/browse/eras" },
  { label: "genres", route: "/browse/genres" },
  { label: "artists", route: "/browse/artists" },
  { label: "songs", route: "/browse/songs" }
];

const Footer = () => <NavBar bottom links={links} />;

export default Footer;
