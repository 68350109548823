import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import {
  KTV_BORDER_GREY,
  KTV_GREY,
  KTV_LIGHT_GREY,
  KTV_NAV_GREY,
  KTV_TEXT_GREY
} from "../constants";

const Ul = styled.ul`
  ${({ bottom }) =>
    bottom
      ? `
          position: fixed;
          bottom 0;
          border-top: 1px solid ${KTV_BORDER_GREY};
        `
      : `border-bottom: 1px solid ${KTV_BORDER_GREY};`}
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: ${KTV_NAV_GREY};

  a {
    display: block;
    color: ${KTV_TEXT_GREY};
    font-size: 1em;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }

  a:hover {
    background: ${KTV_GREY};
  }

  .active {
    background: ${KTV_LIGHT_GREY};
  }
`;

const NavBar = ({ bottom, links }) => (
  <nav>
    <Ul bottom={bottom}>
      {links.map(({ label, route }) => (
        <li>
          <NavLink to={route} activeClassName="active">
            {label}
          </NavLink>
        </li>
      ))}
    </Ul>
  </nav>
);

export default NavBar;
