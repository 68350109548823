import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useTransition, animated } from "react-spring";

/*
 * SlideTransition slides between the outgoing and the incoming filterlist.
 *
 * While the transition is in progress both lists will be rendered via the 2
 * item transitions map. Once the transition is complete the incoming fitlerLost
 * remains and the outgoing filterList is unmounted.
 */

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Frame = styled(animated.div)`
  position: absolute;
  width: 100%;
  will-change: transform;
`;

const Div = styled.div`
  width: 100%;
`;

const browseCategories = ["eras", "genres", "artists", "songs"];

const SlideTransition = ({ browseCategory, render }) => {
  const index = browseCategories.indexOf(browseCategory);
  const prevIndex = useRef(null);
  const transitions = useTransition(index, null, {
    // Incoming filterLists animation starts from here
    from: () => {
      // Do not animate filterLists entrance on arrival to browse section
      if (prevIndex.current === null) return {};

      // Slide incoming FilterList in from left if navigating back
      if (prevIndex.current > index) return { transform: "translateX(-100%)" };

      // Slide incoming FilterList in from right if navigating forward
      return { transform: "translateX(100%)" };
    },

    // Incoming filterLists animation ends here (Outgoing starts from here)
    enter: { transform: "translateX(0%)" },

    // Outgoing filterLists animation ends here
    leave: () =>
      // if navigating backwards
      prevIndex.current > index
        ? { transform: "translateX(100%)" } // slide to right
        : { transform: "translateX(-100%)" } // slide to left
  });

  useEffect(() => { prevIndex.current = index }, [browseCategory]); // eslint-disable-line prettier/prettier

  return (
    <Container>
      {transitions.map(({ item, props }) => (
        <Frame key={item} style={props}>
          <Div>{render(browseCategories[item])}</Div>
        </Frame>
      ))}
    </Container>
  );
};

export default SlideTransition;
