export const INIT_FILTER            = "[filter] Init filter";
export const OSTRACISE_LIST         = "[filter] Ostracise list";
export const RESET_FILTER           = "[filter] Reset";
export const STORE_FILTER           = "[filter] Store filter";
export const STORE_FILTERED_LIBRARY = "[filter] Store filtered library";
export const UPDATE_FILTER          = "[filter] Update filter";

export const initFilter = (browseCategory, filterId) => ({
  type: INIT_FILTER,
  browseCategory,
  filterId
});

export const ostraciseList = (browseCategory, list, songs) => ({
  type: OSTRACISE_LIST,
  browseCategory,
  list,
  songs
});

export const resetFilter = () => ({ type: RESET_FILTER });

export const storeFilter = (browseCategory, filterId, swap) => ({
  type: STORE_FILTER,
  browseCategory,
  filterId,
  swap
});

export const storeFilteredLibrary = (browseCategory, library) => ({
  type: STORE_FILTERED_LIBRARY,
  browseCategory,
  library
});

export const updateFilter = payload => ({
  type: UPDATE_FILTER,
  ...payload
});
