export const CLEAR_FILTERS = "[ui] Clear filters";
export const PICK = "[ui] Pick";
export const SELECT_FILTER = "[ui] Select filter";
export const TOGGLE_FILTER = "[ui] Toggle filter";
export const TOGGLE_PROMPT = "[ui] Toggle prompt";

export const clearFilters = () => ({ type: CLEAR_FILTERS });

export const pick = (songId) => ({
  type: PICK,
  songId,
});

export const selectFilter = (browseCategory, filterId) => ({
  type: SELECT_FILTER,
  browseCategory,
  filterId,
});

export const toggleFilter = (browseCategory, filterId) => ({
  type: TOGGLE_FILTER,
  browseCategory,
  filterId,
});

export const togglePrompt = () => ({ type: TOGGLE_PROMPT });
