import React from "react";
import styled from "styled-components";
import { Icon } from "../design";
import { KTV_BACKGROUND_GREY } from "../constants";

const Container = styled.div`
  list-style-type: none;
  background: ${KTV_BACKGROUND_GREY};
  color: ${KTV_BACKGROUND_GREY};
  margin: 0;
  padding: ${(p) => p.padding}0px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 100vw;
  height: 100%;
`;

const Text = styled.div`
  font-size: ${({ size }) => size};
`;

const Cell = styled.div`
  padding-top: 10px;
`;

const iOS = /iP(hone|ad|od)/i.test(navigator.userAgent);

const Buffer = () => (
  <Container padding={iOS ? "4" : "2"}>
    <Text size="20px">.</Text>
    <Text size="15px">.</Text>
    <Cell>
      <Icon type="ghost" fill={KTV_BACKGROUND_GREY} />
    </Cell>
  </Container>
);

export default Buffer;
