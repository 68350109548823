import React from "react";
import styled from "styled-components";
import { KTV_TEXT_GREY } from "../constants";

const iOS = /iP(hone|ad|od)/i.test(navigator.userAgent);

const StyleButton = styled.button`
  ${(p) =>
    p.iOS &&
    `
    -webkit-appearance: none;
    background-color: ${KTV_TEXT_GREY};
  `}
  margin: 10px 20px;
  padding: 10px 20px;
  min-width: 90px;
`;

const Button = ({ children, ...props }) => (
  <StyleButton iOS={iOS} {...props}>
    {children}
  </StyleButton>
);

export default Button;
