import {
  CLEAR_FILTERS,
  RESET_FILTER,
  STORE_FILTERED_LIBRARY,
  UPDATE_FILTER,
} from "../actions";

const filteredLibrary = (state = [], action) => {
  switch (action.type) {
    case CLEAR_FILTERS:
    case RESET_FILTER:
      return [];
    case STORE_FILTERED_LIBRARY:
      return action.library.selected;
    case UPDATE_FILTER: {
      if (action.swap) return [...new Set(action.library)].sort();
      const newList = state.concat(action.library);
      return [...new Set(newList)].sort();
    }
    default:
      return state;
  }
};

export default filteredLibrary;

export const getSongIds = (state) => state;

export const hasFilterResults = (state) => !!state.length;
