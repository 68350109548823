import React from "react";
import styled from "styled-components";
import NavBar from "./NavBar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

const Body = styled.div`
  flex: 1;
  min-height: 0;
`;

const Header = styled.header`
  flex: 0;
`;

const links = [
  { label: "Browse", route: "/browse/eras" },
  { label: "Party", route: "/party" },
  { label: "Settings", route: "/settings" },
];

const Page = ({ children }) => (
  <Container>
    <Header>
      <NavBar links={links} />
    </Header>
    <Body>{children}</Body>
  </Container>
);

export default Page;
